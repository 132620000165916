<template>
    <section class="compare-vechile page-content">
        <div class="wizard-section d-lg-none bg-white">
            <div class="container">

            </div>
        </div>
        <div class="container">
            <div class="compare-vechile-container main-container">
                <div class="compare-vechile_row">
                  
                        <div class="page-title plain">
                            <h2 class="mb-0">{{ $t('compare_title') }}</h2>
                        </div>
                    
                    
                    <div class="steps-listing text-center">
                        
                        <div class="steps-listing_top">
                            <div class="row gx-0">
                                <div class="col-md-3 col-sm-6">
                                 <div class="steps-listing_top_block">
                                     <p>{{ $t('option') }}</p>
                                 </div>   
                                </div>
                                <template v-for="(data, index) in comparedData" :key="index">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_top_block">
                                            <p> {{ data.policy_name }} </p>
                                        </div>   
                                    </div>
                                </template>
                                    <div class="col-md-3 col-sm-6" v-if="comparedData.length == 2">
                                        <div class="steps-listing_top_block">
                                            
                                        </div>   
                                    </div>
                            </div>
                        </div>

                        <div class="steps-listing_bottom">
                            <div class="steps-listing_bottom_item">
                            <div class="row gx-0">
                                <div class="col-md-3 col-sm-6">
                                 <div class="steps-listing_bottom_item_block">
                                     <p>{{ $t('company') }}</p>
                                 </div>   
                                </div>
                                <template v-for="(data, index) in comparedData" :key="index">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <img class="img-fluid" :src="`${domain}${url}/img/${data.image}`">
                                        </div>   
                                    </div>
                                </template>
                                    <div class="col-md-3 col-sm-6" v-if="comparedData.length == 2">
                                    <div class="steps-listing_bottom_item_block">
                                        
                                    </div>   
                                    </div>
                                </div>
                            </div>

                            
                            <div class="steps-listing_bottom_item">
                            <div class="row gx-0">
                                <div class="col-md-3 col-sm-6">
                                 <div class="steps-listing_bottom_item_block">
                                     <p>{{ $t('price') }}</p>
                                 </div>   
                                </div>
                                <template v-for="(data, index) in comparedData" :key="index">
                                    <div class="col-md-3 col-sm-6">
                                    <div class="steps-listing_bottom_item_block">
                                        <div class="price-box"> 
                                        <div class="price-box--title">
                                            <h3 class="mb-0"> {{ $filters.currency(data.policy_price) }} </h3>
                                        </div>  
                                        <div class="price-box--buy">
                                            <el-button @click.prevent="BuyNow(data)" class="btn btn-buy">{{ $t('buy_now') }}</el-button>
                                        </div>
                                        </div>
                                    </div>   
                                    </div>
                                </template>
                                
                                    <div class="col-md-3 col-sm-6" v-if="comparedData.length == 2">
                                        <div class="steps-listing_bottom_item_block">
                                            <div class="price-box"> 
                                            <div class="price-box--title">
                                                
                                            </div>  
                                            <div class="price-box--buy">
                                                
                                            </div>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                            </div>

                            
                            <div class="steps-listing_bottom_item stripped">
                            <div class="row gx-0">
                                <div class="col-md-3 col-sm-6">
                                 <div class="steps-listing_bottom_item_block">
                                     <p>{{ $t('agency_repair') }}</p>
                                 </div>   
                                </div>
                                <template v-for="(data, index) in comparedData" :key="index">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <div class="icon"><span :class="data.agency_repair && data.agency_repair > 0 ? 'icon-check' : 'icon-close_cross'"></span></div>
                                        </div>   
                                    </div>
                                </template>
                                
                                    <div class="col-md-3 col-sm-6" v-if="comparedData.length == 2">
                                        <div class="steps-listing_bottom_item_block">
                                        
                                        </div>   
                                    </div>
                                </div>
                            </div>

                            <div class="steps-listing_bottom_item">
                            <div class="row gx-0">
                                <div class="col-md-3 col-sm-6">
                                 <div class="steps-listing_bottom_item_block">
                                     <p>{{ $t('personal_accident_passenger') }}</p>
                                 </div>   
                                </div>
                                <template v-for="(data, index) in comparedData" :key="index">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <p class="price" v-if="data.personal_accident_passengers_amt > 0"> <b>{{ $filters.currency(data.personal_accident_passengers_amt) }}</b> </p>
                                            <div v-else class="icon"><span :class="data.personal_accident_passenger ? 'icon-check' : 'icon-close_cross'"></span></div>
                                        </div>   
                                    </div>
                                </template>
                                
                                    <div class="col-md-3 col-sm-6" v-if="comparedData.length == 2">
                                        <div class="steps-listing_bottom_item_block">
                                        
                                        </div>   
                                    </div>
                                </div>
                            </div>

                            <div class="steps-listing_bottom_item stripped">
                                <div class="row gx-0">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <p>{{ $t('personal_accident_driver') }}</p>
                                        </div>   
                                    </div>
                                <template v-for="(data, index) in comparedData" :key="index">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <p class="price" v-if="data.personal_accident_driver > 0"> <b>{{ $filters.currency(data.personal_accident_driver) }}</b> </p>
                                            <div v-else class="icon"><span :class="data.personal_accident_driver ? 'icon-check' : 'icon-close_cross'"></span></div>
                                        </div>   
                                    </div>
                                </template>
                                
                                    <div class="col-md-3 col-sm-6" v-if="comparedData.length == 2">
                                        <div class="steps-listing_bottom_item_block">
                                        
                                        </div>   
                                    </div>
                                </div>
                            </div>

                            <div class="steps-listing_bottom_item">
                                <div class="row gx-0">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <p>{{ $t('roadside_assistance') }}</p>
                                        </div>   
                                    </div>
                                <template v-for="(data, index) in comparedData" :key="index">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <p class="price" v-if="data.roadside_assist_amount > 0"> <b>{{ $filters.currency(data.roadside_assist_amount) }}</b> </p>
                                            <div v-else class="icon"><span :class="data.roadside_assistance && data.roadside_assistance.length > 0 ? 'icon-check' : 'icon-close_cross'"></span></div>
                                        </div>   
                                    </div>
                                </template>
                                
                                    <div class="col-md-3 col-sm-6" v-if="comparedData.length == 2">
                                        <div class="steps-listing_bottom_item_block">
                                        
                                        </div>   
                                    </div>
                                </div>
                            </div>

                            <div class="steps-listing_bottom_item stripped">
                                <div class="row gx-0">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <p>{{ $t('own_damage') }}</p>
                                        </div>   
                                    </div>
                                <template v-for="(data, index) in comparedData" :key="index">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <div class="text" v-if="data.countries"> {{ data.countries.toString() }} </div>
                                        </div>     
                                    </div>
                                </template>
                                
                                    <div class="col-md-3 col-sm-6" v-if="comparedData.length == 2">
                                        <div class="steps-listing_bottom_item_block">
                                        
                                        </div>   
                                    </div>
                                </div>
                            </div>

                            <div class="steps-listing_bottom_item">
                                <div class="row gx-0">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <p>{{ $t('third_party_limit') }}</p>
                                        </div>   
                                    </div>
                                <template v-for="(data, index) in comparedData" :key="index">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <div class="text" v-if="data.third_party_damage_limit"> Up to {{ $filters.currency(data.third_party_damage_limit) || 0 }} </div>
                                        </div>     
                                    </div>
                                </template>
                                
                                    <div class="col-md-3 col-sm-6" v-if="comparedData.length == 2">
                                        <div class="steps-listing_bottom_item_block">
                                        
                                        </div>   
                                    </div>
                                </div>
                            </div>

                            <div class="steps-listing_bottom_item stripped">
                                <div class="row gx-0">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <p>{{ $t('excess_amount') }}</p>
                                        </div>   
                                    </div>
                                <template v-for="(data, index) in comparedData" :key="index">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <div class="text" v-if="data.excess_amount"> {{ $filters.currency(data.excess_amount) || 0 }} </div>
                                        </div>     
                                    </div>
                                </template>
                                
                                    <div class="col-md-3 col-sm-6" v-if="comparedData.length == 2">
                                        <div class="steps-listing_bottom_item_block">
                                        
                                        </div>   
                                    </div>
                                </div>
                            </div>

                            <div class="steps-listing_bottom_item">
                                <div class="row gx-0">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <p>{{ $t('roadside_assistance') }}</p>
                                        </div>   
                                    </div>
                                <template v-for="(data, index) in comparedData" :key="index">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <div class="text" style="text-align: left; font-weight: normal"> <ul><li v-for="(assistance, index) in data.roadside_assistance" :key="index"> {{ assistance.title }} </li></ul> </div>
                                        </div>     
                                    </div>
                                </template>
                                
                                    <div class="col-md-3 col-sm-6" v-if="comparedData.length == 2">
                                        <div class="steps-listing_bottom_item_block">
                                        
                                        </div>   
                                    </div>
                                </div>
                            </div>

                            <div class="steps-listing_bottom_item stripped">
                                <div class="row gx-0">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <p>{{ $t('highlights') }}</p>
                                        </div>   
                                    </div>
                                <template v-for="(data, index) in comparedData" :key="index">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <div class="text" style="text-align: left; font-weight: normal"> <ul><li v-for="(policy, index) in data.car_policy_highlight_title" :key="index"> {{ policy.highlight }} </li></ul> </div>
                                        </div>     
                                    </div>
                                </template>
                                
                                    <div class="col-md-3 col-sm-6" v-if="comparedData.length == 2">
                                        <div class="steps-listing_bottom_item_block">
                                        
                                        </div>   
                                    </div>
                                </div>
                            </div>

                            <div class="steps-listing_bottom_item">
                                <div class="row gx-0">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <p>{{ $t('special_feature') }}</p>
                                        </div>   
                                    </div>
                                <template v-for="(data, index) in comparedData" :key="index">
                                    <div class="col-md-3 col-sm-6">
                                        <div class="steps-listing_bottom_item_block">
                                            <div class="text" style="text-align: center; font-weight: normal" v-html="data.special_features"> </div>
                                        </div>     
                                    </div>
                                </template>
                                
                                    <div class="col-md-3 col-sm-6" v-if="comparedData.length == 2">
                                        <div class="steps-listing_bottom_item_block">
                                        
                                        </div>   
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';
import { useStore } from '@/store';
import Personal from '@/layouts/reusable/Personal.vue';
import Buy from '@/core/services/car/BuyNow';
import router from '@/router';
import { useReCaptcha } from 'vue-recaptcha-v3'

export default defineComponent({
    components: {
        Personal
    },

    setup() {

        const store = useStore();
        const captcha = useReCaptcha()

        const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN)
        const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL)

        const comparedData = computed(() => {
            return store.state.qoutes.comparedData
        })

         // handleClick
        const BuyNow = async (val) => {
            // load captcha
            await captcha?.recaptchaLoaded()
            // execute reCaptcha
            const token = await captcha?.executeRecaptcha('login');

            const date = new Date();
            const expiryDate = date.setDate(date.getDate() + 30)
            const dateExpiry = new Date(expiryDate).toISOString()
            const data = {
                value: val,
                uuid_dd: window.localStorage.getItem('fall_back_dd'),
                uuid_vd: window.localStorage.getItem('fall_back'),
                payment_type: 1,
                order_description: "Online Generated Quotation",
                is_amend: 0,
                policy_sales_agent_id: 1,
                original_price: val.policy_price,
                sale_price: val.policy_price,
                language: "en",
                is_agency_repair: val.agency_repair || 2,
                bai_car_insurance_policy_id: val.id || val.car_insurance_id,
                expiry_date_time: dateExpiry.split('T')[0] + ' 23:59:59',
                manufacturer_id: JSON.parse(window.localStorage.getItem('customer_detatils')).manufacturer_id,
                model_id: JSON.parse(window.localStorage.getItem('customer_detatils')).model_id,
                trim_level_id: JSON.parse(window.localStorage.getItem('customer_detatils')).trim_level_id
            }
            
            if(token) {
                Buy.getQoutationReference(data)
                router.push({name: 'SecureChecout'})
            }
        }

        return {
            captcha,
            comparedData,
            domain,
            url,
            BuyNow
        }
    },
})
</script>
