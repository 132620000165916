<template>
  <section class="compare-vechile page-content">
    <head_mobile />
    <div class="container">
      <div class="compare-vechile-container main-container">
        <div class="compare-vechile_row">
          <div class="page-title plain">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="mb-0">{{ $t('compare_plan') }} </h2>
              <div class="custom-button text-center">
                <a @click="$router.go(-1)" class="btn"> {{ $t('back') }} </a>
              </div>
            </div>
          </div>

          <div class="compare-plans-table">
            <div class="compare-plans-table_block">
              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left"></div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="compare-company text-center">
                          <div class="compare-company_logo">
                            <img
                              class="img-fluid"
                              :src="`${domain}${url}/img/${data.image}`"
                            />
                          </div>
                          <div class="compare-company_name">
                            <h6 class="mb-0">{{ data.policy_name }}</h6>
                            <p>{{ data.type }}</p>
                          </div>
                          <div class="compare-company_price">
                            <h6 class="mb-0">
                              {{ $filters.currency(data.policy_price) }}
                            </h6>
                          </div>
                          <div class="proceed">
                            <a
                              href="#"
                              class="btn"
                              @click.prevent="BuyNow(data)"
                              > {{ $t('proceed') }} </a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('agency_repair') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div
                          :class="
                            data.agency_repair && data.is_agency != 'No'
                              ? 'check'
                              : 'cross'
                          "
                        >
                          <img
                            v-if="data.agency_repair && data.is_agency != 'No'"
                            src="@/assets/images/icons/check-compare.png"
                            class="img-fluid"
                          />
                          <img
                            v-else
                            src="@/assets/images/icons/cross-compare.png"
                            class="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('personal_accident_passenger') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div
                          :class="
                            data.personal_accident_passenger
                              ? 'check'
                              : 'cross'
                          "
                        >
                          <img
                            v-if="data.personal_accident_passenger"
                            src="@/assets/images/icons/check-compare.png"
                            class="img-fluid"
                          />
                          <img
                            v-else
                            src="@/assets/images/icons/cross-compare.png"
                            class="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('personal_accident_driver') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div
                          :class="
                            data.personal_accident_driver ? 'check' : 'cross'
                          "
                        >
                          <img
                            v-if="data.personal_accident_driver"
                            src="@/assets/images/icons/check-compare.png"
                            class="img-fluid"
                          />
                          <img
                            v-else
                            src="@/assets/images/icons/cross-compare.png"
                            class="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('roadside_assistance') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div
                          :class="
                            data.roadside_assistance &&
                            data.roadside_assistance.applicable
                              ? 'check'
                              : 'cross'
                          "
                        >
                          <img
                            v-if="
                              data.roadside_assistance &&
                              data.roadside_assistance.applicable
                            "
                            src="@/assets/images/icons/check-compare.png"
                            class="img-fluid"
                          />
                          <img
                            v-else
                            src="@/assets/images/icons/cross-compare.png"
                            class="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('own_damage') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="desc-p" v-if="data.policy_type == 5">
                          <div class="cross">
                            <img src="@/assets/images/icons/cross-compare.png" class="img-fluid" />
                          </div>
                        </div>
                        <div class="desc-p" v-else>
                          <p v-if="data.own_damage_cover">
                            {{ data.own_damage_cover.value }}
                          </p>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('excess_amount') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="desc-p">
                          <p class="amount" v-if="data.excess_amount && data.excess_amount.applicable">
                            {{ $filters.currency(data.excess_amount.value) }}
                          </p>
                          <p class="amount" v-else>
                            {{ $filters.currency(0) }}
                          </p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('car_coverage') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="desc-p">
                          <ul>
                            <template
                               v-for="(
                                item, index
                              ) in data.benefit_car_coverage"
                              :key="index"
                            >
                              <li
                                v-if="item.applicable"
                              >
                                {{ item.title }}
                              </li>
                            </template>
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('extra_coverage') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="desc-p">
                          <ul>
                            <template
                               v-for="(
                                item, index
                              ) in data.benefit_extra_coverage"
                              :key="index"
                            >
                              <li
                                v-if="item.applicable"
                              >
                                {{ item.title }}
                              </li>
                            </template>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('special_feature') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="desc-p">
                          <ul>
                            <template
                               v-for="(
                                item, index
                              ) in data.benefit_special_features"
                              :key="index"
                            >
                              <li
                                v-if="item.applicable"
                              >
                                {{ item.title }}
                              </li>
                            </template>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left"></div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="proceed">
                          <a @click.prevent="BuyNow(data)" class="btn"
                            > {{ $t('proceed') }} </a
                          >
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import Qoutes from "@/core/services/car/Qoutes";
import Title from "@/layouts/reusable/Title.vue";
import head_mobile from '@/layouts/head_mobile.vue';
import CompareDetails from "@/components/Compare.vue";
import { store } from "@/store";
import Buy from "@/core/services/car/BuyNow";
import router from "@/router";
import { useReCaptcha } from "vue-recaptcha-v3";

    const captcha = useReCaptcha();

    const current = computed(() => {
      return store.state.steppers.stepper;
    });

    onMounted(() => {
      const data = {
        uuid: window.localStorage.getItem("fall_back_dd"),
        policyIds: JSON.parse(window.localStorage.getItem("compare_data")),
      }
      const driver_id = router.currentRoute.value.query.driver_id
      if(driver_id) data.driver_id = driver_id
      Qoutes.getComparePolicies(data);
    })

    const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN);
    const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL);

    const comparedData = computed(() => {
      // return store.state.qoutes.comparedData;
      return JSON.parse(window.localStorage.getItem("compare_data"));
    });

    // handleClick
    const BuyNow = async (val) => {
      // load captcha
      await captcha?.recaptchaLoaded();
      // execute reCaptcha
      const token = await captcha?.executeRecaptcha("login");

      const date = new Date();
      const expiryDate = date.setDate(date.getDate() + 30);
      const dateExpiry = new Date(expiryDate).toISOString();
      const data = {
        value: val,
        // uuid_dd: window.localStorage.getItem("fall_back_dd"),
        // uuid_vd: window.localStorage.getItem("fall_back"),
        payment_type: 1,
        order_description: "Online Generated Quotation",
        is_amend: 0,
        policy_sales_agent_id: 1,
        original_price: val.policy_price,
        policy_price: val.policy_price,
        sale_price: val.policy_price,
        language: language.value,
        is_agency_repair: val.agency_repair.value || 2,
        bai_car_insurance_policy_id: val.id || val.car_insurance_id,
        expiry_date_time: dateExpiry.split("T")[0] + " 23:59:59",
        // manufacturer_id: JSON.parse(
        //   window.localStorage.getItem("customer_detatils")
        // ).manufacturer_id,
        // model_id: JSON.parse(window.localStorage.getItem("customer_detatils"))
        //   .model_id,
        // trim_level_id: JSON.parse(
        //   window.localStorage.getItem("customer_detatils")
        // ).trim_level_id,
        uuid: router.currentRoute.value.query.uuid
      };
      const driver_id = router.currentRoute.value.query.driver_id;
      if(driver_id) data.driver_id = driver_id

      if (token) {
        const result = await Buy.getQoutationReference(data);
        if (result.status == 200) {
          // router.push({ name: "SecureChecout" });
          const fullPath = router.currentRoute.value.fullPath
          const path = fullPath.split('?')
          window.location.href = `/secure-checkout?${path[1]}`
        }
      }
    };

    const language = computed(() => {
      return window.localStorage.getItem("language");
    });

</script>
